import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardMedia } from '@mui/material';

function MyServices() {
  // Sample services data
  const services = [
    {
      title: 'Supervision and design of engineering projects - الإشراف والتصميم على المشروعات الهندسية',
      description: 'Build modern and responsive websites that provide excellent user experiences.',
      image: 'https://via.placeholder.com/200', // Replace with actual image URL
    },
    {
      title: 'Geographic information systems - نظم المعلومات الجغرافية',
      description: 'Create mobile applications that are fast, intuitive, and optimized for all devices.',
      image: 'https://via.placeholder.com/200', // Replace with actual image URL
    },
    {
      title: 'Aerial photography - التصوير الجوى',
      description: 'Improve your website’s visibility and rankings on search engines like Google.',
      image: 'https://via.placeholder.com/200', // Replace with actual image URL
    },
    {
      title: 'Smart surveying using smart technologies such as panoramic surveying (Mobile Mapping) and surveying infrastructure networks using (GPR) devices - و المسح الذكي باستخدام التقنيات الذكية مثل المسح البانورامي (Mobile Mapping ) ومسح شبكات البنية التحتية باستخدام أجهزة (GPR)',
      description: 'Design user-friendly interfaces with a focus on creating great user experiences.',
      image: 'https://via.placeholder.com/200', // Replace with actual image URL
    },
    {
      title: 'Creating integrated databases - انشاء قواعد بيانات متكاملة',
      description: 'Develop custom web applications that meet your business requirements.',
      image: 'https://via.placeholder.com/200', // Replace with actual image URL
    },
    {
      title: 'Data analysis, management and modeling and helping decision makers make the appropriate decision - تحليل البيانات و إدارتها و نمذجتها ومساعدة متخذي القرارات على اتخاذ القرار المناسب',
      description: 'Optimize your website’s performance to ensure fast loading speeds and smooth user experiences.',
      image: 'https://via.placeholder.com/200', // Replace with actual image URL
    },
  ];

  return (
    <Box sx={{ textAlign: 'center', padding: 4 }}>
      <Typography variant="h3" gutterBottom>
        My Services
      </Typography>
      <Typography variant="body1" paragraph>
        This is the Services page where you can list all the services you offer. I provide a range of high-quality services to meet your business needs.
      </Typography>

      <Grid container spacing={4}>
        {services.map((service, index) => (
          <Grid item xs={12} sm={6} md={3} key={index}>
            <Card sx={{ maxWidth: 345, margin: 'auto' }}>
              <CardMedia
                component="img"
                height="140"
                image={service.image}
                alt={service.title}
              />
              <CardContent>
                <Typography variant="h5" gutterBottom>
                  {service.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {service.description}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default MyServices;
