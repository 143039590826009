import React from 'react';
import { Box, Typography } from '@mui/material';

function Contact() {
  return (
    <Box sx={{ textAlign: 'center', padding: 4 }}>
      <Typography variant="h3" gutterBottom>
        Contact Me
      </Typography>
      <Typography variant="body1">
        Use this page to provide your contact details or a form for inquiries.
      </Typography>
    </Box>
  );
}

export default Contact;
