import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Typography, IconButton } from '@mui/material';
import { Facebook, Twitter, LinkedIn, Instagram } from '@mui/icons-material';
import Home from './Home';
import MyServices from './MyServices';
import About from './About';
import Contact from './Contact';

import salahKandilLogo from './assets/salah-kandil-logo.svg';

function Navbar() {
  const [activeTab, setActiveTab] = useState('home');

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Box sx={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>
      <Button
        component={Link}
        to="/"
        sx={{
          color: activeTab === 'home' ? 'red' : '#333',
          textTransform: 'none',
          '&:hover': {
            color: 'red',
          },
        }}
        onClick={() => handleTabClick('home')}
      >
        Home
      </Button>
      <Button
        component={Link}
        to="/about"
        sx={{
          color: activeTab === 'about' ? 'red' : '#333',
          textTransform: 'none',
          '&:hover': {
            color: 'red',
          },
        }}
        onClick={() => handleTabClick('about')}
      >
        About
      </Button>
      <Button
        component={Link}
        to="/my-services"
        sx={{
          color: activeTab === 'my-services' ? 'red' : '#333',
          textTransform: 'none',
          '&:hover': {
            color: 'red',
          },
        }}
        onClick={() => handleTabClick('my-services')}
      >
        My Services
      </Button>
      <Button
        component={Link}
        to="/contact"
        sx={{
          color: activeTab === 'contact' ? 'red' : '#333',
          textTransform: 'none',
          '&:hover': {
            color: 'red',
          },
        }}
        onClick={() => handleTabClick('contact')}
      >
        Contact
      </Button>
    </Box>
  );
}

function Footer() {
  return (
    <Box
      component="footer"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: 2,
        backgroundColor: '#f5f5f5',
        borderTop: '1px solid #ddd',
        marginTop: 'auto',
      }}
    >
      <Typography variant="h6" gutterBottom>
        Reach Me
      </Typography>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <IconButton
          href="https://www.facebook.com/salahaldeen0844"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#3b5998' }}
        >
          <Facebook />
        </IconButton>
        <IconButton
          href="https://x.com/salah_Kandil"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#1da1f2' }}
        >
          <Twitter />
        </IconButton>
        <IconButton
          href="https://www.linkedin.com/in/salah-kandil-70b7149a/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#0077b5' }}
        >
          <LinkedIn />
        </IconButton>
        <IconButton
          href="https://www.instagram.com/salahaldeen_yahia/"
          target="_blank"
          rel="noopener noreferrer"
          sx={{ color: '#e4405f' }}
        >
          <Instagram />
        </IconButton>
      </Box>
      <Typography variant="body2" sx={{ marginTop: 1, color: '#555' }}>
        © {new Date().getFullYear()} Salah Kandil. All rights reserved.
      </Typography>
    </Box>
  );
}

function App() {
  return (
    <Router>
      <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
        <AppBar
          position="sticky"
          sx={{
            backgroundColor: 'white',
            boxShadow: 'none',
            borderBottom: '1px solid #e0e0e0',
            maxWidth: '1200px',
            margin: '0 auto',
            left: 0,
            right: 0,
            marginLeft: 'auto',
            marginRight: 'auto',
          }}
        >
          <Toolbar>
            <a href="/" style={{ textDecoration: 'none' }}>
              <img
                src={salahKandilLogo}
                alt="Salah Kandil Logo"
                style={{ maxWidth: 180, width: '100%' }}
              />
            </a>

            {/* Navbar */}
            <Navbar />

          </Toolbar>
        </AppBar>

        <main style={{ flex: 1 }}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/my-services" element={<MyServices />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
        </main>

        {/* Footer */}
        <Footer />
      </div>
    </Router>
  );
}

export default App;
