import React from 'react';
import { Box, Typography, Grid, Avatar, Button } from '@mui/material';

import salahImage1 from './assets/salah-img1.jpeg';

function About() {
  return (
    <Box sx={{ padding: 4, backgroundColor: '#f4f4f9' }}>
      <Typography variant="h3" gutterBottom align="center" color="primary">
        About Salah
      </Typography>
      
      <Grid container spacing={4} justifyContent="center">
        <Grid item xs={12} sm={6} md={4} display="flex" justifyContent="center">
          <Avatar
            src={salahImage1}
            alt="Salah Kandil"
            sx={{
              width: 180,
              height: 180,
              borderRadius: '50%',
              boxShadow: 3,
            }}
          />
        </Grid>
        
        <Grid item xs={12} sm={8} md={6}>
          <Typography variant="h5" gutterBottom align="center">
            Hello! I'm Salah Kandil, a Passionate Developer
          </Typography>
          
          <Typography variant="body1" paragraph>
            I am a web developer with a passion for building innovative and dynamic websites. 
            With a background in design and technology, I love blending aesthetics with functionality 
            to create beautiful, user-friendly digital experiences. I specialize in frontend development, 
            but I also have experience with backend technologies and am always looking to improve my skills.
          </Typography>

          <Typography variant="body1" paragraph>
            Over the years, I've worked on various projects ranging from personal blogs to e-commerce 
            platforms, helping clients create online presences that resonate with their audience. I'm constantly 
            learning new technologies and methodologies to stay up-to-date with the latest trends in web development.
          </Typography>

          <Box display="flex" justifyContent="center" mt={2}>
            <Button
              variant="contained"
              color="primary"
              href="mailto:"
            >
              Get in Touch
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default About;
