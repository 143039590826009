import React from 'react';
import { Button, Box, Typography } from '@mui/material';

import salahImage1 from './assets/salah-img1.jpeg';

function Home() {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '50px 20px',
                maxWidth: '1200px',
                margin: '0 auto',
            }}
        >
            <Box sx={{ maxWidth: '50%' }}>
                <Typography variant="h2" component="h1" gutterBottom color="primary">
                    Assalamu'alaikum,
                </Typography>
                <Typography variant="h4" component="h1" gutterBottom>
                    Welcome to my domain, my name is Salah Kandil
                </Typography>
                <Box>
                    <Button
                        variant="contained"
                        color="primary"
                        sx={{ margin: '20px 10px 0 0' }}
                    >Contact Me</Button>
                </Box>
            </Box>
            <Box sx={{ maxWidth: '50%' }}>
                <style>
                    {`
                        @keyframes rotateIn {
                            0% {
                                transform: rotateY(-180deg);
                                opacity: 0;
                            }
                            100% {
                                transform: rotateY(0deg);
                                opacity: 1;
                            }
                        }

                        .rotate-in {
                            width: 100%;
                            border-radius: 8px;
                            opacity: 0; /* Initially invisible */
                            animation: rotateIn 0.3s forwards; /* Apply rotation and fade-in */
                            transform-style: preserve-3d; /* Required for 3D transforms */
                        }
                    `}
                </style>
                <img
                    src={salahImage1} 
                    alt="Salah Kandil"
                    className="rotate-in" 
                />
            </Box>
        </Box>
    );
}

export default Home;
